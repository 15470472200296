const url = "/api/v2/report/";

export const ReportApiUrls = {
  // accommodation по замкам
  async getKeyReports(params) {
    return (
      await window.axios.get(url + "key/", {
        params: params
      })
    ).data;
  },
  // accommodation по броням
  async getOrderReports(params) {
    return (
      await window.axios.get(url + "booking/", {
        params: params
      })
    ).data;
  },
  // accommodation по оплатам
  async getPayReports(params) {
    return (
      await window.axios.get(url + "payment/", {
        params: params
      })
    ).data;
  },
  // accommodation по клиентам
  async getClientReports(params) {
    return (
      await window.axios.get(url + "client/", {
        params: params
      })
    ).data;
  },
  // accommodation по номерам
  async getRoomReports(params) {
    return (
      await window.axios.get(url + "room/", {
        params: params
      })
    ).data;
  },
  // accommodation по загрузке
  async getRoomZagReports(params) {
    return (
      await window.axios.get(url + "room-workload/", {
        params: params
      })
    ).data;
  },
  // accommodation по источникам
  async getSourceReports(params) {
    return (
      await window.axios.get(url + "source/", {
        params: params
      })
    ).data;
  },
  // accommodation по услугам
  async getServiceReports(params) {
    return (
      await window.axios.get(url + "service/", {
        params: params
      })
    ).data;
  },
  // accommodation по кухне
  async getKitchenReports(params) {
    return (
      await window.axios.get(url + "kitchen-v2/", {
        params: params
      })
    ).data;
  },

  async getKitchenData(params) {
    return (
      await window.axios.get(url + "kitchen-calculate-v2/", {
        params: params
      })
    ).data;
  },

  // leisure по броням
  async getLeisureBookingsReports(params) {
    return (
      await window.axios.get(url + "leisure-booking/", {
        params: params
      })
    ).data;
  },
  // leisure по тарифам
  async getLeisureTarifReports(params) {
    return await window.axios.get(url + "leisure-tariff/", {
      params: params
    });
  },

  // get данные о ценах
  async getTotal(type, params) {
    return (
      await window.axios.get(`${url}calculation/${type}/`, {
        params: params
      })
    ).data;
  },

  // get данные о ценах
  async getLeisureTotal(type, params) {
    return (
      await window.axios.get(`${url}${type}/`, {
        params: params
      })
    ).data;
  },

  // get скачать эксель
  async getExcel(url_sub, params) {
    return (
      await window.axios.get(`${url}${url_sub}/`, {
        params: params,
        responseType: "blob",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      })
    ).data;
  },

  async getTalonReports(params) {
    return (
      await window.axios.get(url + "talon/", {
        params: params
      })
    ).data;
  }
};
